import React, { useRef } from "react";
import { useState, useEffect } from "react";
import autoTable from "jspdf-autotable";
import firebase from "firebase";
import save from "./../../Assets/saveBtn.svg";
import Modal from "react-modal";
import { debounce } from "lodash";
import jsPDF from "jspdf";

import "jquery/dist/jquery.min.js";

import "datatables.net-dt/js/dataTables.dataTables";

import $ from "jquery";
import "./MasterList.scss";
import Loader from "../../Components/Loader/Loader";

import editIcon from "../../Assets/edit_icon.png";

import crossIcon from "./../../Assets/cross-icon.svg";
import Footer from "../../Components/Footer/Footer";
import { Typography, Stack } from "@mui/material";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    width: "550px",
    right: "auto",
    bottom: "auto",
    borderRadius: "24px",
    boxShadow:
      "-16px -16px 40px rgba(253, 255, 255, 0.8), 16px 16px 40px rgba(187, 195, 206, 0.6)",
    padding: "0px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};

const headers = [
  "Select",
  "Provider Name",
  "Provider ID",
  "Practioner ID",
  "4 hr Rate",
  "8 hr Rate",
  "10 hr Rate",
  "12 hr Rate",
  "15 hr Rate",
  "Location",
];
const inputElements = [
  "Provider Name",
  "ProviderID",
  "PractionerID",
  "Rate4",
  "Rate8",
  "Rate10",
  "Rate12",
  "Rate15",
  "Location",
];

function MasterList() {
  const ref = firebase.firestore().collection("Master List2");
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    provName: "",
    provId: "",
    pracId: "",
    provRate4: "",
    provRate8: "",
    provRate10: "",
    provRate12: "",
    provRate15: "",
    provLocation: "",
  });

  const [edit, setEdit] = useState(false);
  const [loader, setLoader] = useState(true);
  const [isAuth, setIsAuth] = useState(
    localStorage.getItem("role") === "admin"
  );
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState(false);
  const [editedList, setEditedList] = useState(new Set());
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [confirmDeleteModalIsOpen, setConfirmDeleteModalIsOpen] =
    useState(false);

  const getData = async () => {
    console.log("getData is called");
    setLoader(true);

    try {
      ref.onSnapshot((querySnapshot) => {
        const items = querySnapshot.docs.map((doc) => doc.data());
        console.log(items, "items");
        setData(items);
      });
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setLoader(false);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const addNewProvider = async () => {
    const {
      provName,
      provRate4,
      provRate8,
      provRate10,
      provRate12,
      provRate15,
      provLocation,
      provId,
      pracId,
    } = formData;

    // Generate a unique identifier
    const _id = await generateId(20);

    const tableInstance = $("#example").DataTable();
    tableInstance.destroy();

    try {
      setLoader(true);
      await ref.doc(_id).set({
        _id, // Using _id instead of uniqueId
        "Provider Name": provName,
        Location: provLocation,
        ProviderID: provId || "",
        PractionerID: pracId || "",
        Rate4: parseFloat(provRate4),
        Rate8: parseFloat(provRate8),
        Rate10: parseFloat(provRate10),
        Rate12: parseFloat(provRate12),
        Rate15: parseFloat(provRate15),
      });

      resetFormData();
      setModalIsOpen(false);
      await getData();
      await createBackupPDF();
    } catch (error) {
      console.error("Error adding new provider: ", error);
    } finally {
      setLoader(false);
    }
  };

  const createBackupPDF = async () => {
    try {
      const backupData = [];
      const querySnapshot = await ref.get();
      querySnapshot.forEach((doc) => backupData.push(doc.data()));

      const doc = new jsPDF();
      autoTable(doc, {
        head: [
          [
            "Provider Name",
            "Provider ID",
            "Practioner ID",
            "Rate4",
            "Rate8",
            "Rate10",
            "Rate12",
            "Rate15",
            "Location",
          ],
        ],
        body: backupData.map((bd) => [
          bd["Provider Name"],
          bd.ProviderID,
          bd.PractionerID,
          bd.Rate4,
          bd.Rate8,
          bd.Rate10,
          bd.Rate12,
          bd.Rate15,
          bd.Location,
        ]),
      });
      const pdfBlob = doc.output("blob");
      await firebase.storage().ref().child("masterListBackup.pdf").put(pdfBlob);
    } catch (error) {
      console.error("Error creating backup PDF: ", error);
    }
  };

  const updateList = debounce((e, key, field) => {
    const value = e.target.value;
    const updatedData = [...data]; // Copy the data array

    // Ensure consistent data types for rates and IDs
    if (field.includes("Rate")) {
      updatedData[key][field] = value === "" ? 0 : parseFloat(value);
    } else if (field === "ProviderID" || field === "PractionerID") {
      updatedData[key][field] = value.toString(); // Convert IDs to strings
    } else {
      updatedData[key][field] = value;
    }

    const updatedItem = updatedData[key];

    // Clean up any null values
    Object.keys(updatedItem).forEach((key) => {
      if (updatedItem[key] === null) {
        delete updatedItem[key];
      }
    });

    setEditedList((prevEditedList) => {
      const newEditedList = new Set(prevEditedList);
      newEditedList.add(updatedItem);
      return newEditedList;
    });
  }, 300);

  const updateAfterEdit = async () => {
    try {
      setLoader(true);
      const tableInstance = $("#example").DataTable();
      tableInstance.destroy();

      const updates = await Promise.all(
        Array.from(editedList).map(async (data) => {
          console.log(data, "data to update");
          
          // First try to find the document
          const querySnapshot = await ref
            .where("_id", "==", data._id)
            .limit(1)
            .get();

          if (querySnapshot.empty) {
            console.error(`No document found with _id: ${data._id}`);
            return null;
          }

          const docRef = querySnapshot.docs[0].ref;
          const updatedData = {
            _id: data._id,
            "Provider Name": data["Provider Name"] || "",
            Location: data.Location || "",
            ProviderID: data.ProviderID?.toString() || "",
            PractionerID: data.PractionerID?.toString() || "",
            Rate4: parseFloat(data.Rate4) || 0,
            Rate8: parseFloat(data.Rate8) || 0,
            Rate10: parseFloat(data.Rate10) || 0,
            Rate12: parseFloat(data.Rate12) || 0,
            Rate15: parseFloat(data.Rate15) || 0,
          };

          return docRef.update(updatedData);
        })
      );

      const validUpdates = updates.filter(update => update !== null);
      await Promise.all(validUpdates);

      await createBackupPDF();
      await getData();
      setAlertMsg(true);
      editedList.clear();
    } catch (error) {
      console.error("Error updating documents: ", error);
    } finally {
      setLoader(false);
      setEdit(false);
    }
  };

  const resetFormData = () => {
    setFormData({
      provName: "",
      provId: "",
      pracId: "",
      provRate4: "",
      provRate8: "",
      provRate10: "",
      provRate12: "",
      provRate15: "",
      provLocation: "",
    });
  };

  async function generateId(length = 20) {
    const randomBytes = crypto.getRandomValues(new Uint8Array(8));
    const input = [...randomBytes, ...new TextEncoder().encode(Date.now().toString())];
    const hashBuffer = await crypto.subtle.digest("SHA-256", new Uint8Array(input)); 
    return Array.from(new Uint8Array(hashBuffer))
      .map(b => b.toString(16).padStart(2, '0'))
      .join('')
      .substring(0, length);
  }

  const deleteSelectedRecords = async () => {
    try {
      setLoader(true);
      const tableInstance = $("#example").DataTable();
      tableInstance.destroy();

      const deletePromises = selectedRecords.map(async (selectedId) => {
        // Find document by _id field
        const querySnapshot = await ref
          .where("_id", "==", selectedId)
          .limit(1)
          .get();

        if (querySnapshot.empty) {
          console.error(`No document found with _id: ${selectedId}`);
          return null;
        }

        // Delete using the correct document reference
        return querySnapshot.docs[0].ref.delete();
      });

      const results = await Promise.all(deletePromises);
      const validDeletes = results.filter(result => result !== null);
      
      if (validDeletes.length !== selectedRecords.length) {
        console.warn(`Only ${validDeletes.length} out of ${selectedRecords.length} records were deleted`);
      }

      setSelectedRecords([]);
      await getData();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting records: ", error);
    } finally {
      setLoader(false);
    }
  };

  const openConfirmDeleteModal = () => {
    setConfirmDeleteModalIsOpen(true);
  };

  const closeConfirmDeleteModal = () => {
    setConfirmDeleteModalIsOpen(false);
  };

  const handleConfirmDelete = async () => {
    closeConfirmDeleteModal();
    await deleteSelectedRecords();
  };

  // const migrateExistingRecords = async () => {
  //   try {
  //     const querySnapshot = await ref.get();
  //     const batch = firebase.firestore().batch();
      
  //     for (const doc of querySnapshot.docs) {
  //       const data = doc.data();
  //       if (!data._id) {
  //         const _id = await generateId(20);  // await here is fine since we're in async function
  //         batch.update(doc.ref, { _id });
  //       }
  //     }

  //     await batch.commit();
  //     console.log("Migration completed successfully");
  //   } catch (error) {
  //     console.error("Migration failed:", error);
  //   }
  // };

  useEffect(() => {
    // migrateExistingRecords().then(() => {
    //   getData();
    // });
    getData();
  }, []);

  useEffect(() => {
    const initializeDataTable = () => {
      // Destroy previous DataTable instance if exists
      const tableInstance = $.fn.DataTable.isDataTable("#example")
        ? $("#example").DataTable()
        : null;
      if (tableInstance) {
        tableInstance.destroy();
      }

      // Reinitialize DataTable
      $("#example").DataTable({
        paging: false, // Enable paging
        info: false, // Show table info
        searching: true, // Enable search functionality
      });

      console.log(tableInstance, "TABLEINSTANCE");
    };

    // Initialize DataTable only if data exists
    if (data.length) {
      initializeDataTable();
      console.log("data.length", data.length);
    }
  }, [data]);

  return (
    <div className="master-list">
      <Loader loading={loader} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        contentLabel="Provider Modal"
      >
        <div className="master-modal">
          <div
            style={{
              position: "relative",
              tranform: "translate-X(20px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {" "}
            <div
              className="modal-close-icon"
              onClick={() => setModalIsOpen(false)}
            >
              <img
                src={crossIcon}
                loading="lazy"
                alt=""
                style={{ width: "15px" }}
              />
            </div>{" "}
          </div>
          <div
            className="master-modal-body"
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "380px",
              paddingLeft: "90px",
              paddingRight: "90px",
              paddingTop: "90px",
              paddingBottom: "90px",
            }}
          >
            <input
              type="text"
              placeholder="Enter Provider Name"
              onChange={(e) => handleInputChange(e)}
              name="provName"
              value={formData.provName}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                type="number"
                placeholder="Enter 4 Hr Rate"
                style={{ width: "40%" }}
                name="provRate4"
                value={formData.provRate4}
                onChange={(e) => handleInputChange(e)}
              />
              <input
                type="number"
                placeholder="Enter 8 Hr Rate"
                style={{ width: "40%" }}
                name="provRate8"
                value={formData.provRate8}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                type="number"
                placeholder="Enter 10 Hr Rate"
                style={{ width: "40%" }}
                name="provRate10"
                value={formData.provRate10}
                onChange={(e) => handleInputChange(e)}
              />
              <input
                type="number"
                placeholder="Enter 12 Hr Rate"
                style={{ width: "40%" }}
                onChange={(e) => handleInputChange(e)}
                name="provRate12"
                value={formData.provRate12}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                type="number"
                placeholder="Enter 15 Hr Rate"
                style={{ width: "40%" }}
                onChange={(e) => handleInputChange(e)}
                name="provRate15"
                value={formData.provRate15}
              />
              <input
                type="number"
                placeholder="Enter Provider Id"
                style={{ width: "40%" }}
                onChange={(e) => handleInputChange(e)}
                name="provId"
                value={formData.provId}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <input
                type="number"
                placeholder="Enter Practitioner Id"
                style={{ width: "40%" }}
                onChange={(e) => handleInputChange(e)}
                name="pracId"
                value={formData.pracId}
              />
              <input
                type="text"
                placeholder="Enter Location"
                onChange={(e) => handleInputChange(e)}
                name="provLocation"
                value={formData.provLocation}
              />
            </div>

            <button
              type="submit"
              style={{
                marginTop: "20px",
                maxWidth: "150px",
                width: "100px",
                alignSelf: "center",
              }}
              onClick={addNewProvider}
            >
              {/* <img src={save} alt="save" /> */}
              <div>SAVE</div>
            </button>
          </div>
        </div>
      </Modal>

      <div
        className="custom-alert"
        style={{
          opacity: alertMsg ? 1 : 0,
          zIndex: alertMsg ? "9999999" : "-1",
        }}
      >
        <div
          style={{
            position: "relative",
            tranform: "translate-X(20px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {" "}
          <div
            className="modal-close-icon"
            onClick={() => {
              setAlertMsg(false);
            }}
          >
            <img
              src={crossIcon}
              alt=""
              style={{ width: "15px" }}
              loading="lazy"
            />
          </div>{" "}
        </div>
        <div className="alert-text-wrapper">Your changes have been saved</div>
      </div>

      <br />

      {isAuth && (
        <div
          className="button_div master-table-top sticky"
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            <button
              disabled={edit}
              onClick={() => setModalIsOpen(true)}
              className="button-div-add add-provider"
              style={{ fontWeight: "600" }}
            > 
              + Add New Provider
            </button>
            <button
              disabled={edit || selectedRecords.length === 0}
              onClick={openConfirmDeleteModal}
              className="button-div-add add-provider"
              style={{ fontWeight: "600" }}
            >
              Delete Selected
            </button>
          </div>

          <h3
            style={{
              color: "#0762a3",
              position: "absolute",
              width: "100%",
              textAlign: "center",
              display: "inline",
              zIndex: "-1",
            }}
          >
            Master Rate List
          </h3>

          {edit ? (
            <button style={{ alignSelf: "flex-end" }} onClick={updateAfterEdit}>
              {" "}
              <img
                src={save}
                alt="save"
                loading="lazy"
                style={{
                  height: "40%",
                  margin: "auto",
                  height: "15px",
                  paddingBottom: "3px",
                }}
              />{" "}
              <p style={{ fontWeight: "600" }}>Save</p>
            </button>
          ) : (
            <button
              onClick={() => setEdit(true)}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={editIcon}
                alt="save"
                loading="lazy"
                style={{
                  height: "40%",
                  margin: "auto",
                  height: "15px",
                  paddingBottom: "3px",
                }}
              />{" "}
              <p style={{ fontWeight: "600" }}>Edit</p>
            </button>
          )}
        </div>
      )}

      <table
        className=" display table"
        id="example"
        style={{ marginTop: "20px" }}
      >
        <thead>
          <tr>
            {headers
              ? headers.map((data, key) => {
                  return (
                    <th scope="col" key={key}>
                      <p>{data}</p>
                    </th>
                  );
                })
              : null}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => {
            return (
              <tr
                className={index % 2 !== 0 ? "color" : "non-color"}
                key={`${item.ProviderID}-${item.PractionerID}-${index}`}
              >
                <td>
                  <input
                    id={`checkbox-${
                      item.ProviderID || item.PractionerID
                    }-${index}`}
                    type="checkbox"
                    checked={selectedRecords.includes(item._id)}
                    onChange={(e) => {
                      setSelectedRecords((prev) => {
                        if (e.target.checked) {
                          return [...prev, item._id];
                        } else {
                          return prev.filter((prevId) => prevId !== item._id);
                        }
                      });
                    }}
                    disabled={edit}
                  />
                </td>
                {inputElements.map((header, i) => (
                  <td key={`${item.ProviderID}-${header}-${i}`}>
                    {edit ? (
                      <p className="td-input-parent">
                        <input
                          type="text"
                          defaultValue={item[header]}
                          onChange={(e) => updateList(e, index, header)}
                          id={`input-${item.ProviderID}-${header}-${i}`}
                        />
                      </p>
                    ) : (
                      <p>{item[header]}</p>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Footer />

      {/* Confirmation Modal */}
      <Modal
        isOpen={confirmDeleteModalIsOpen}
        onRequestClose={closeConfirmDeleteModal}
        style={customStyles}
        contentLabel="Delete Confirmation"
      >
        <div className="master-modal">
          <div
            style={{
              position: "relative",
              tranform: "translate-X(20px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="modal-close-icon" onClick={closeConfirmDeleteModal}>
              <img src={crossIcon} alt="" style={{ width: "15px" }} />
            </div>
          </div>
          <div
            className="master-modal-body"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography width="85%" mb="-10px" style={{ color: "white" }}>
              Are you sure you want to delete the selected records?
            </Typography>
            <Stack direction="row" gap="20px">
              <button type="submit" onClick={handleConfirmDelete}>
                <div style={{ fontWeight: "600" }}>Yes</div>
              </button>
              <button type="submit" onClick={closeConfirmDeleteModal}>
                <div style={{ fontWeight: "600" }}>No</div>
              </button>
            </Stack>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MasterList;
